import React from "react";

import {
  Headerlogo,
  aboutImage,
  aboutLogo,
  avatar,
  bannerImagePerson,
  briefCase,
  company1,
  company2,
  company3,
  company4,
  company5,
  footerImage,
  footerLogo,
  idea,
  infoLogo,
  speak,
} from "./assets";

import "../assets/css/LandingPage.css";
import Slider from "react-slick";

import LandingPageController, { Props } from "./LandingPageController";
import {
  Avatar,
  Box,
  Typography,
  withStyles,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
const baseURL = require("../../../framework/src/config.js").baseURL;
import TutorialVideoGuest from "../../../components/src/TutorialVideo.web";
import i18n from "../../../components/src/TranslateLanguage";
import i18nJs from "../../../components/src/TranslateLanguage";

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const settings1 = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  centerMode: false,
  slidesToScroll: 3,
  centerPadding: "24px",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings2 = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: true,
  centerPadding: "0px",
  className: "center",
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export let renderImages = (objs: any) => {
  const courseImageUrl = objs?.course?.data?.attributes?.course_image?.url;

  return courseImageUrl ? (
    <img
      style={{ height: "253px" }}
      data-test-id="id1"
      src={`${baseURL}${courseImageUrl}`}
      className="course-image"
    />
  ) : (
    <div
      style={{
        height: "253px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No image available
    </div>
  );

}

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const landingData = this.state.landingPCat;

    return (
      // Required for all blocks
      <div className={`${i18n.locale === 'en' ? "main-landing-wrapper" : "main-landing-wrapper main_landing_arabic_wrapper"}`} data-test-id="landing_page_container" dir={i18n.locale === 'ar' ? 'rtl' : 'ltr'}>
        <TutorialVideoGuest
          tutorialVideoSrc={this.state.tutorialVideoSrc}
          setTutorialDialogOpen={this.state.setTutorialDialogOpen}
          isPlaying={this.state.isPlaying}
          isHover={this.state.isHover}
          videoRef={this.state.videoRef}
          handleSkipBackwardTutorial={this.handleSkipBackwardTutorial}
          handleSkipForwardTutorial={this.handleSkipForwardTutorial}
          togglePlayPauseTutorial={this.togglePlayPauseTutorial}
          handleVideoTutorialPlay={this.handleVideoTutorialPlay}
          handleVideoTutorialPause={this.handleVideoTutorialPause}
          onMouseEnterTutorial={this.onMouseEnterTutorial}
          onMouseLeaveTutorial={this.onMouseLeaveTutorial}
          handleCloseTutorial={this.handleCloseTutorial}
        />
        <header className="site-header">
          <div className="container">
            <div
              className={`${this.state.menu_collape === false
                ? "mobile-menu"
                : "mobile-menu menu-mobile"
                }`}
            >
              <svg
                data-test-id="menyu_true"
                onClick={() => this.onClickMenuCollapeOn()}
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
              <svg
                data-test-id="menyu_false"
                onClick={() => this.onClickMenuCollapeOff()}
                className="close-menu-landing"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
              <div className="landing-header-wrapper">
                <div className="header-logo">
                  <div>
                    <img src={Headerlogo} alt="Header logo" />
                  </div>
                </div>
                <div className="landing-navigation">
                  <nav className="main-navigation">
                    <ul>
                      <li style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <button data-test-id="switchLang" style={{ border: 'none', backgroundColor: '#37657f', color: '#ffffff', padding: '5px 10px', borderRadius: '5px' }} onClick={() => this.switchLanguage('ar')} disabled={i18n.locale === "ar" ? true : false}>Ar</button>
                        <button data-test-id="switchLang1" style={{ border: 'none', backgroundColor: '#37657f', color: '#ffffff', padding: '5px 10px', borderRadius: '5px' }} onClick={() => this.switchLanguage('en')} disabled={i18n.locale === "en" ? true : false}>En</button>
                      </li>
                      <li>
                        <div
                          data-test-id="header-about-us"
                          onClick={() =>
                            this.onClickRedirect(
                              this.state.userToken
                                ? "AboutRaffles"
                                : "GuestAboutus"
                            )
                          }
                        >
                          {i18n.t('ABOUT_US')}
                        </div>
                      </li>
                      <li>
                        <div
                          data-test-id="header-courses"
                          onClick={() => this.onClickRedirect("GuestCourse")}
                        >
                          {i18n.t('COURSES')}
                        </div>
                      </li>
                      <li>
                        <div
                          data-test-id="header-contact-us"
                          onClick={() =>
                            this.onClickRedirect(
                              this.state.userToken
                                ? "Contactus"
                                : "GuestContactus"
                            )
                          }
                        >
                          {i18n.t('CONTACT_US')}
                        </div>
                      </li>

                    </ul>
                  </nav>
                  <div className="header-button">
                    <button
                      type="button"
                      data-test-id="testPassOne"
                      onClick={() => this.onClickRedirectName("Home")}
                      className="button-link"
                    >
                      {i18n.t('SIGN_IN')}
                    </button>
                    <button
                      type="button"
                      data-test-id="testPassTwo"
                      onClick={() => this.onClickRedirectName("Home")}
                      className="button"
                    >
                      {i18n.t('CREATE_NEW_ACCOUNT')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="landing-content">
          <section className="banner-section">
            <div className="container">
              <div className="banner-wrapper">
                <div className="banner-content">
                  <h1>
                    <span>{i18n.t('EXPLORE')}</span> {i18n.t('OUR_ONLINE_COURSES_TO')}{" "}
                    <span>{i18n.t('ENHANCE')}</span> {i18n.t('YOUR')} <span>{i18n.t('SKILLS')}</span> {i18n.t('AND')}{" "}
                    <span>{i18n.t('EXPERTISE')}</span>.
                  </h1>
                  <p>{i18n.t('DESIGN_YOUR_FUTURE_WITH_RAFFLES')}</p>
                  <div className="banner-button">
                    <button
                      data-test-id="get-started-button"
                      onClick={() => this.onClickRedirectName("Home")}
                      type="button"
                      className="button"
                    >
                      {i18n.t('GET_STARTED')}
                    </button>
                    <button
                      className="button-white"
                      data-test-id="open-dialog-tutorial-button"
                      onClick={this.handleClickOpen}
                    >
                      {i18n.t('SEE_HOW_IT_WORKS')}
                    </button>
                  </div>
                  <ul>
                    <li>
                      <img src={speak} alt="Speking" /> {i18n.t('PUBLIC_SPEAKING')}
                    </li>
                    <li>
                      <img src={briefCase} alt="Briefcase" /> {i18n.t('CAREER_ORIENTED')}
                    </li>
                    <li>
                      <img src={idea} alt="Idea" /> {i18n.t('CREATIVE_THINKING')}
                    </li>
                  </ul>
                </div>
                <div className="banner-image">
                  <div className="overlay-bg" />
                  <div className="banner-image-wrapper">
                    <img src={bannerImagePerson} alt="Banner" />
                  </div>
                  <div className="banenr-learning">
                    <p>
                      <span>1000+</span> {i18n.t('STUDENTS_LEARNING')}
                    </p>
                  </div>
                  <div className="banner-course-block banner-outline">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="81"
                      height="81"
                      viewBox="0 0 81 81"
                      fill="none"
                    >
                      <path
                        d="M40.8594 4.36035C45.587 4.36035 50.2683 5.29152 54.636 7.10069C59.0037 8.90986 62.9723 11.5616 66.3152 14.9045C69.6581 18.2474 72.3099 22.216 74.119 26.5838C75.9282 30.9515 76.8594 35.6328 76.8594 40.3604C76.8594 45.0879 75.9282 49.7692 74.119 54.137C72.3099 58.5047 69.6581 62.4733 66.3152 65.8162C62.9723 69.1591 59.0037 71.8109 54.636 73.62C50.2682 75.4292 45.587 76.3604 40.8594 76.3604C36.1318 76.3603 31.4505 75.4292 27.0828 73.62C22.715 71.8108 18.7464 69.1591 15.4035 65.8162C12.0606 62.4733 9.40887 58.5047 7.59971 54.1369C5.79054 49.7692 4.85937 45.0879 4.85938 40.3603C4.85938 35.6327 5.79055 30.9515 7.59972 26.5837C9.40889 22.216 12.0606 18.2474 15.4035 14.9045C18.7465 11.5616 22.7151 8.90985 27.0828 7.10068C31.4505 5.29151 36.1318 4.36035 40.8594 4.36035L40.8594 4.36035Z"
                        stroke="#EAECF0"
                        strokeWidth="8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M40.8594 4.36035C48.4618 4.36035 55.8691 6.76713 62.0196 11.2357C68.1702 15.7044 72.7481 22.0054 75.0974 29.2357C77.4467 36.4661 77.4467 44.2546 75.0974 51.485C72.7481 58.7153 68.1702 65.0164 62.0196 69.485"
                        stroke="#37657F"
                        strokeWidth="8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>50+</span>
                    <p>{i18n.t('ONLINE_COURSES')}</p>
                  </div>
                  <div className="banner-tutors-block banner-outline">
                    <div className="banner-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M16.6048 13.6629C17.8503 14.5087 18.723 15.6545 18.723 17.1822V19.9104H22.3594V17.1822C22.3594 15.1998 19.1139 14.0267 16.6048 13.6629Z"
                          fill="white"
                        />
                        <path
                          d="M15.0866 12.6354C17.0957 12.6354 18.723 11.0076 18.723 8.99785C18.723 6.98813 17.0957 5.36035 15.0866 5.36035C14.6594 5.36035 14.2594 5.45129 13.8776 5.5786C14.6321 6.51526 15.0866 7.70654 15.0866 8.99785C15.0866 10.2892 14.6321 11.4804 13.8776 12.4171C14.2594 12.5444 14.6594 12.6354 15.0866 12.6354Z"
                          fill="white"
                        />
                        <path
                          d="M9.6321 12.6354C11.6412 12.6354 13.2685 11.0076 13.2685 8.99785C13.2685 6.98813 11.6412 5.36035 9.6321 5.36035C7.62301 5.36035 5.99574 6.98813 5.99574 8.99785C5.99574 11.0076 7.62301 12.6354 9.6321 12.6354ZM9.6321 7.1791C10.6321 7.1791 11.4503 7.99754 11.4503 8.99785C11.4503 9.99816 10.6321 10.8166 9.6321 10.8166C8.6321 10.8166 7.81392 9.99816 7.81392 8.99785C7.81392 7.99754 8.6321 7.1791 9.6321 7.1791Z"
                          fill="white"
                        />
                        <path
                          d="M9.6321 13.5447C7.20483 13.5447 2.35938 14.7633 2.35938 17.1822V19.9104H16.9048V17.1822C16.9048 14.7633 12.0594 13.5447 9.6321 13.5447ZM15.0866 18.0916H4.17756V17.1913C4.35938 16.5366 7.17756 15.3635 9.6321 15.3635C12.0866 15.3635 14.9048 16.5366 15.0866 17.1822V18.0916Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="banner-tutors-content">
                      <p>{i18n.t('TUTORS')}</p>
                      <span>50+</span>
                    </div>
                  </div>
                  <div className="banner-videos-block banner-outline">
                    <div className="banner-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.3594 3.41992H3.35938C2.25937 3.41992 1.35938 4.31992 1.35938 5.41992V17.4199C1.35938 18.5199 2.25937 19.4199 3.35938 19.4199H8.35938V20.4199C8.35938 20.9699 8.80937 21.4199 9.35938 21.4199H15.3594C15.9094 21.4199 16.3594 20.9699 16.3594 20.4199V19.4199H21.3594C22.4594 19.4199 23.3494 18.5199 23.3494 17.4199L23.3594 5.41992C23.3594 4.30992 22.4594 3.41992 21.3594 3.41992ZM20.3594 17.4199H4.35938C3.80938 17.4199 3.35938 16.9699 3.35938 16.4199V6.41992C3.35938 5.86992 3.80938 5.41992 4.35938 5.41992H20.3594C20.9094 5.41992 21.3594 5.86992 21.3594 6.41992V16.4199C21.3594 16.9699 20.9094 17.4199 20.3594 17.4199ZM10.8594 14.5699L14.8394 12.2899C15.5094 11.8999 15.5094 10.9399 14.8394 10.5499L10.8594 8.26992C10.1894 7.88992 9.35938 8.36992 9.35938 9.13992V13.6999C9.35938 14.4599 10.1894 14.9499 10.8594 14.5699Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="banner-videos-content">
                      <span>100+</span>
                      <p>{i18n.t('VIDEOS')}</p>
                    </div>
                  </div>
                  <div className="banner-learning-dot-block">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <circle cx="7" cy="7" r="7" fill="#37657F" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="top-company-section">
            <div className="container">
              <div className="company-content">
                <h3>{i18n.t('TRUSTED_BY_TOP_100_COMPANIES')}</h3>
                <ul>
                  <li>
                    <img src={company1} alt="Company1" />
                  </li>
                  <li>
                    <img src={company2} alt="Company2" />
                  </li>
                  <li>
                    <img src={company3} alt="Company3" />
                  </li>
                  <li>
                    <img src={company4} alt="Company4" />
                  </li>
                  <li>
                    <img src={company5} alt="Company5" />
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="program-section">
            <div className="container">
              <div className="program-title-block">
                <div className="program-title">
                  <h2>{i18n.t('EXPLORE_PROGRAMS')}</h2>
                  <p>{i18n.t('OUR_MOST_POPULAR_CLASSES')}</p>
                </div>
                <button
                  onClick={() => this.onClickRedirectName("GuestCourse")}
                  type="button"
                  data-test-id="browseCourse"
                  className="button-white"
                >
                  {i18n.t('BROWSE_COURSES')}
                </button>
              </div>
              <Slider {...settings1} className="slider">
                {this.state.allPopularCourses?.map((objs: any, index: any) => {
                  return (
                    <div
                      data-test-id="test1"
                      className="program-slide-block"
                      key={index}
                    >
                      <div
                        id="test2"
                        className="program-wrapper"
                        style={{ position: "relative" }}
                      >
                        <Box
                          style={{
                            position: "absolute",
                            background: "white",
                            top: 0,
                            right: 0,
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            borderRadius: "0px 9px 0px 10px",
                            color: "#37657F",
                          }}
                        >
                          <AccessTimeIcon
                            style={{
                              fontSize: "16px",
                              marginRight: "5px",
                              color: "#37657F",
                            }}
                          />
                          <Typography variant="body2">
                            {this.convertDurationHours(
                              objs.course.data.attributes
                                .total_duration_of_lessons
                            )}
                          </Typography>
                        </Box>
                        {renderImages(objs)}
                        <div className="program-content test3">
                          <h2 data-test-id="test4">
                          {this.truncateText(objs.course.data.attributes.category?.data.attributes.name, 20)}
                          </h2>
                          <div id="test5" className="program-content-item">
                            <h4 className="test6 no-break-text">
                            {this.truncateText(objs.course.data.attributes.course_name, 20)}
                            </h4>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M7 17L17 7M17 7H7M17 7V17"
                                  stroke="#101828"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <p
                            data-test-id="test7"
                            className="program-content-description"
                          >
                            {this.truncateText(objs.course.data.attributes.description, 70)}
                          </p>
                          <div className="program-inner-block test-8">
                            <div id="test-9" className="program-inner-content">
                              {objs.course.data.attributes.profile_picture ? (
                                <img
                                  src={
                                    baseURL +
                                    objs.course.data.attributes.profile_picture
                                      .url
                                  }
                                />
                              ) : (
                                <Avatar
                                  {...this.stringAvatar(
                                    objs.course.data.attributes.lecturer
                                  )}
                                />
                              )}

                              <div className="program-inner-content-item test-10">
                                <h5>{this.truncateText(objs.course.data.attributes.lecturer, 16)}</h5>
                                <p>{objs.enrolled_count} Enrolled</p>
                              </div>
                            </div>
                            <span> د.إ{objs.course.data.attributes.price}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </section>

          <section className="about-section">
            <div className="container">
              <div className="about-wrapper">
                <div className="about-image">
                  <img src={aboutImage} alt="About" />
                </div>
                <div className="about-content">
                  <img src={aboutLogo} alt="About Logo" />
                  <h5>{i18n.t('ABOUT_US')}</h5>
                  <p>
                    {i18n.t('RAFFLES_EDUCATION_INSTITUTE_ETC')}
                    <br />
                    <br />
                    {i18n.t('WITHIN_5_YEARS_ETC')}
                    <br />
                    <br />
                    {i18n.t('IN_2004_RAFFLES_DESIGN_ETC')}
                  </p>
                  {/* <p>Within 5 years, multiple majors were offered at Raffles Institute, including (graphic design - product design  jewelry design  interior design  game design  animation design).</p>
                                    <p>In 2004, Raffles Design Institute was awarded the Singapore Quality Readiness for Special Education Organizations. In 2005, Raffles was awarded the CaseTrust Award for Education.In 2008, Raffles Design Institute was recognized by Edupoll as one of the top ten educational institutions in Singapore and the largest provider of private education globally.</p> */}
                </div>
              </div>
            </div>
          </section>

          <section className="service-section">
            <div className="container">
              <div className="service-title">
                <h2>{i18n.t('OUR_SERVICES')}</h2>
                <p>
                  {i18n.t('DESIGN_YOUR_FUTURE_WITH_OUR_GLOBAL_NETWORK')}
                </p>
              </div>
              <Slider {...settings2} className="slider">
                {landingData?.map((item: any) =>
                  <div className="service-slide-block" key={item.id}>
                    <div className="service-wrapper">
                      <div className="service-content-block">
                        <div className="service-content-image">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                          >
                            <path
                              d="M22 3.5H4C3.72386 3.5 3.5 3.72386 3.5 4V15H22.5V4C22.5 3.72386 22.2761 3.5 22 3.5Z" stroke="white"
                            />

                            <path
                              d="M24.5 15.1176C24.7761 15.1176 25 14.8938 25 14.6176V3.5C24.9983 2.11997 23.88 1.00165 22.5 1H3.5C2.11997 1.00165 1.00165 2.11997 1 3.5V17.5C1.00165 18.88 2.11997 19.9983 3.5 20H9V22.2325L7.7225 23.0825C7.58302 23.1756 7.49947 23.3323 7.5 23.5V24C7.5 24.5523 7.94772 25 8.5 25H17.5C18.0523 25 18.5 24.5523 18.5 24V23.5C18.5 23.3329 18.4165 23.1768 18.2775 23.084L17 22.2325V20.0882C17 19.8121 16.7761 19.5882 16.5 19.5882C16.2239 19.5882 16 19.8121 16 20.0882V22.5C16 22.6671 16.0835 22.8232 16.2225 22.916L17.5 23.7675V24H8.5V23.7675L9.7775 22.9175C9.91698 22.8244 10.0005 22.6677 10 22.5V20H22.5C23.88 19.9983 24.9983 18.88 25 17.5V15C25 14.7239 24.7761 14.5 24.5 14.5H1.88235C1.60621 14.5 1.38235 14.7239 1.38235 15C1.38235 15.2761 1.60621 15.5 1.88235 15.5H24V17.5C24 18.3284 23.3284 19 22.5 19H3.5C2.67157 19 2 18.3284 2 17.5V3.5C2 2.67157 2.67157 2 3.5 2H22.5C23.3284 2 24 2.67157 24 3.5V14.6176C24 14.8938 24.2239 15.1176 24.5 15.1176Z"
                              fill="white"
                              stroke="white"
                              strokeWidth="0.4"
                            />
                            <path
                              d="M10.8534 5.35147C10.6581 5.15628 10.3416 5.15628 10.1464 5.35147L7.14639 8.35147C6.9512 8.54672 6.9512 8.86322 7.14639 9.05847L10.1464 12.0585C10.3426 12.248 10.6544 12.2452 10.8473 12.0524C11.0402 11.8595 11.0429 11.5477 10.8534 11.3515L8.20689 8.70497L10.8534 6.05847C11.0486 5.86322 11.0486 5.54672 10.8534 5.35147Z"
                              fill="white"
                            />
                            <path
                              d="M15.1468 12.0589C15.342 12.2541 15.6585 12.2541 15.8538 12.0589L18.8538 9.05888C19.049 8.86363 19.049 8.54713 18.8538 8.35188L15.8538 5.35188C15.7283 5.2219 15.5424 5.16977 15.3675 5.21553C15.1927 5.26128 15.0562 5.39781 15.0104 5.57262C14.9647 5.74744 15.0168 5.93334 15.1468 6.05888L17.7933 8.70538L15.1468 11.3519C14.9516 11.5471 14.9516 11.8636 15.1468 12.0589Z"
                              fill="white"
                            />

                          </svg>

                        </div>
                        <h3>
                          {i18nJs.locale === 'en' ? item?.attributes?.name : item?.attributes?.name_arb}
                        </h3>
                      </div>
                      <div className="service-content-item">
                        <p style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 3,
                          maxHeight: "4.5em",
                        }}>
                          {i18nJs.locale === 'en'
                            ? this.truncateText(item?.attributes?.description, 80)
                            : this.truncateText(item?.attributes?.description_ar, 80)
                          }
                        </p>
                        <div data-test-id="course-learn-more"
                          onClick={() => this.handleLearnMore(item?.attributes?.id)} >
                          {i18n.t('LEARN_MORE')}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="7" height="12"
                            viewBox="0 0 7 12" fill="none"
                          >
                            <path
                              d="M1 11L6 6L1 1" stroke="#37657F" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </section>

          <section className="info-section">
            <div className="container">
              <Slider {...settings}>
                <div data-test-id="test55" className="info-content">
                  <img src={infoLogo} alt="Info Logo" />
                  <h2 id="test56">
                    {i18n.t('COURSES_WERE_EXCELLENT')}
                  </h2>
                  <img className="test57" src={avatar} alt="Avtar" />
                  <h5 data-test-id="test58">{i18n.t('RAFFLES_JR')}</h5>
                  <p id="test59">{i18n.t('STUDENT_DUBAI_NATIONAL_UNIVERSITY')}</p>
                </div>
                <div data-test-id="test60" className="info-content">
                  <img src={infoLogo} alt="Info Logo" />
                  <h2 id="test61">
                    {i18n.t('COURSES_WERE_EXCELLENT')}
                  </h2>
                  <img className="test62" src={avatar} alt="Avtar" />
                  <h5 data-test-id="test63">{i18n.t('RAFFLES_JR')}</h5>
                  <p id="test64">{i18n.t('STUDENT_DUBAI_NATIONAL_UNIVERSITY')}</p>
                </div>
                <div className="info-content">
                  <img src={infoLogo} alt="Info Logo" />
                  <h2>
                    {i18n.t('COURSES_WERE_EXCELLENT')}
                  </h2>
                  <img src={avatar} alt="Avtar" />
                  <h5>{i18n.t('RAFFLES_JR')}</h5>
                  <p>{i18n.t('STUDENT_DUBAI_NATIONAL_UNIVERSITY')}</p>
                </div>
              </Slider>
            </div>
          </section>
        </div>

        <footer className="site-footer">
          <div className="container">
            <div className="main-footer">
              <div className="footer-top">
                <div className="footer-top-left">
                  <img src={footerLogo} alt="Footer Logo" />
                  <h4>
                    {i18n.t('TOP_LEARNING_EXPERIENCES_THAT_CREATE')} <br /> {i18n.t('MORE_TALENT_IN_THE_WORLD')}
                  </h4>
                  <ul>
                    <li>
                      <div
                        data-test-id="footer-about-us"
                        onClick={() => this.onClickRedirect("GuestAboutus")}
                      >
                        {i18n.t('ABOUT_US')}
                      </div>
                    </li>
                    <li>
                      <div
                        data-test-id="footer-courses"
                        onClick={() => this.onClickRedirect("GuestCourse")}
                      >
                        {i18n.t('COURSES')}
                      </div>
                    </li>
                    <li>
                      <div
                        data-test-id="footer-contact-us"
                        onClick={() => this.onClickRedirect("GuestContactus")}
                      >
                        {i18n.t('CONTACT_US')}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="footer-top-right">
                  <img src={footerImage} alt="FooterPicture" />
                </div>
              </div>
              <div className="footer-bottom">
                <div className="footer-bottom-left">
                  <p>&copy; {i18n.t('EASTERN_TAM_KEEN_ALL_RIGHTS_RESERVED')}</p>
                </div>
                <div className="footer-bottom-right">
                  <ul>
                    <li>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7.55016 21.7507C16.6045 21.7507 21.5583 14.2474 21.5583 7.74259C21.5583 7.53166 21.5536 7.31603 21.5442 7.10509C22.5079 6.40819 23.3395 5.54499 24 4.55603C23.1025 4.95533 22.1496 5.21611 21.1739 5.32947C22.2013 4.71364 22.9705 3.7462 23.3391 2.6065C22.3726 3.17929 21.3156 3.58334 20.2134 3.80134C19.4708 3.01229 18.489 2.48985 17.4197 2.31478C16.3504 2.13972 15.2532 2.32178 14.2977 2.83283C13.3423 3.34387 12.5818 4.15544 12.1338 5.14204C11.6859 6.12865 11.5754 7.23535 11.8195 8.29103C9.86249 8.19282 7.94794 7.68444 6.19998 6.79883C4.45203 5.91323 2.90969 4.67017 1.67297 3.15025C1.0444 4.23398 0.852057 5.51638 1.13503 6.73682C1.418 7.95727 2.15506 9.02418 3.19641 9.72072C2.41463 9.6959 1.64998 9.48541 0.965625 9.10666V9.16759C0.964925 10.3049 1.3581 11.4073 2.07831 12.2875C2.79852 13.1677 3.80132 13.7713 4.91625 13.9957C4.19206 14.1939 3.43198 14.2227 2.69484 14.0801C3.00945 15.0582 3.62157 15.9136 4.44577 16.5271C5.26997 17.1405 6.26512 17.4813 7.29234 17.502C5.54842 18.8718 3.39417 19.6149 1.17656 19.6113C0.783287 19.6107 0.390399 19.5866 0 19.5392C2.25286 20.9845 4.87353 21.7521 7.55016 21.7507Z"
                            fill="#98A2B3"
                          />
                        </svg>
                      </div>
                    </li>
                    <li>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2389_17480)">
                            <path
                              d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                              fill="#98A2B3"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2389_17480">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </li>
                    <li>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2389_17483)">
                            <path
                              d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                              fill="#98A2B3"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2389_17483">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </li>
                    <li>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_2389_17485)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 0C5.3724 0 0 5.3808 0 12.0204C0 17.3304 3.438 21.8364 8.2068 23.4252C8.8068 23.5356 9.0252 23.1648 9.0252 22.8456C9.0252 22.5612 9.0156 21.804 9.0096 20.802C5.6712 21.528 4.9668 19.1904 4.9668 19.1904C4.422 17.8008 3.6348 17.4312 3.6348 17.4312C2.5452 16.6872 3.7176 16.7016 3.7176 16.7016C4.9212 16.7856 5.5548 17.94 5.5548 17.94C6.6252 19.776 8.364 19.2456 9.0468 18.9384C9.1572 18.162 9.4668 17.6328 9.81 17.3328C7.146 17.0292 4.344 15.9972 4.344 11.3916C4.344 10.08 4.812 9.006 5.5788 8.166C5.4552 7.8624 5.0436 6.6396 5.6964 4.986C5.6964 4.986 6.7044 4.662 8.9964 6.2172C9.97532 5.95022 10.9853 5.81423 12 5.8128C13.02 5.8176 14.046 5.9508 15.0048 6.2172C17.2956 4.662 18.3012 4.9848 18.3012 4.9848C18.9564 6.6396 18.5436 7.8624 18.4212 8.166C19.1892 9.006 19.6548 10.08 19.6548 11.3916C19.6548 16.0092 16.848 17.0256 14.1756 17.3232C14.6064 17.694 14.9892 18.4272 14.9892 19.5492C14.9892 21.1548 14.9748 22.452 14.9748 22.8456C14.9748 23.1672 15.1908 23.5416 15.8004 23.424C18.19 22.6225 20.2672 21.0904 21.7386 19.0441C23.2099 16.9977 24.001 14.5408 24 12.0204C24 5.3808 18.6264 0 12 0Z"
                              fill="#98A2B3"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2389_17485">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </li>
                    <li>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 0C5.37527 0 0 5.37527 0 12C0 18.6248 5.37527 24 12 24C18.6117 24 24 18.6248 24 12C24 5.37527 18.6117 0 12 0ZM19.9262 5.53145C21.3579 7.27549 22.217 9.50107 22.243 11.9089C21.9046 11.8439 18.5206 11.154 15.1106 11.5835C15.0325 11.4143 14.9675 11.2321 14.8894 11.0499C14.6811 10.5554 14.4469 10.0477 14.2126 9.56618C17.9869 8.0304 19.705 5.81779 19.9262 5.53145ZM12 1.77007C14.603 1.77007 16.9848 2.74621 18.7939 4.34707C18.6117 4.60738 17.0629 6.67679 13.4186 8.04338C11.7397 4.95878 9.87855 2.43384 9.5922 2.04338C10.3601 1.86117 11.1671 1.77007 12 1.77007ZM7.63995 2.73319C7.91325 3.09761 9.73538 5.63558 11.4404 8.65508C6.65076 9.9306 2.42083 9.90458 1.96529 9.90458C2.62907 6.72885 4.77657 4.08676 7.63995 2.73319ZM1.74404 12.0131C1.74404 11.9089 1.74404 11.8048 1.74404 11.7007C2.18655 11.7136 7.15835 11.7787 12.2733 10.243C12.5727 10.8156 12.846 11.4013 13.1063 11.9869C12.9761 12.026 12.8329 12.0651 12.7028 12.1041C7.41865 13.8091 4.60738 18.4685 4.3731 18.859C2.7462 17.0499 1.74404 14.6421 1.74404 12.0131ZM12 22.256C9.6312 22.256 7.44469 21.449 5.71367 20.0954C5.89588 19.718 7.97827 15.7094 13.757 13.692C13.783 13.679 13.7961 13.679 13.8221 13.666C15.2668 17.4013 15.8525 20.5379 16.0087 21.436C14.7722 21.9696 13.4186 22.256 12 22.256ZM17.7136 20.4989C17.6096 19.8742 17.0629 16.8807 15.7223 13.1974C18.9371 12.6898 21.7484 13.5228 22.0998 13.6399C21.6573 16.4902 20.0173 18.9501 17.7136 20.4989Z"
                            fill="#98A2B3"
                          />
                        </svg>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const webStyles = {
  dialogTitle: {
    padding: "40px 40px 0",
    margin: "0 0 25px",
  },
  dialogHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  typographyTitle: {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "bold" as const,
    lineHeight: "normal",
    marginBottom: "8px",
  },
  typographyParagraph: {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal" as const,
    lineHeight: "normal",
  },
  closeButton: {
    position: "absolute" as const,
    cursor: "pointer",
    right: "48px",
    top: "40px",
    background: "rgba(217, 217, 217, 0.50)",
    width: "35px",
    height: "35px",
    textAlign: "center" as const,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tutorialModalContentLanding: {
    padding: "0 40px 40px",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#205e83",
    },
  },
  fashionContentImageLanding: {
    position: "relative" as const,
    margin: "0 0 40px",
  },
  playPauseBlockLanding: {
    position: "absolute" as const,
  },
  mainBlockLanding: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "absolute" as const,
    top: 0,
    height: "50%",
    width: "100%",
  },
  pauseButonLanding: {
    cursor: "pointer",
    width: 50,
    height: 50,
    fill: "white",
    "@media (max-width: 575px)": {
      height: 35,
    },
  },
  playButtonLanding: {
    cursor: "pointer",
    "@media (max-width: 575px)": {
      height: 35,
    },
  },
  nextIconLanding: {
    position: "relative" as const,
    left: 180,
    cursor: "pointer",
    "& svg": {
      transform: "rotateY(180deg)",
    },
    "@media (max-width: 767px)": {
      left: 120,
    },
    "@media (max-width: 575px)": {
      left: 50,
      "& svg": {
        height: 30,
      },
    },
  },
  backIconLanding: {
    position: "relative" as const,
    right: 180,
    cursor: "pointer",
    "@media (max-width: 767px)": {
      right: 120,
    },
    "@media (max-width: 575px)": {
      right: 50,
      "& svg": {
        height: 30,
      },
    },
  },
  mainBlock: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "absolute" as const,
    top: 0,
    height: "50%",
    width: "100%",
  },
  videoTag: {
    borderRadius: "20px",
    width: "100%",
    height: "553px",
    objectFit: "fill" as const,
    "@media (max-width: 767px)": {
      height: "368px",
    },
  },
  tutorialModalTitleLanding: {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "bold" as const,
    lineHeight: "normal",
    marginBottom: "8px",
  },

  tutorialModalTitleContentLanding: {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal" as const,
    lineHeight: "normal",
  },

  designflex1Landing: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    borderLeft: "4px solid #37657F",
    background: "#37657f4a",
    padding: "12px 16px",
    margin: "10px 0px",
    marginBottom: 0,
    "& p": {
      color: "#37657F",
      fontFamily: "Corbel V2",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
    },
  },
  designflex2Landing: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    background: "var(--status-red-100, #FEE2E2)",
    padding: "12px 16px",
    margin: "10px 0px",
    marginBottom: 0,
    "& p": {
      color: "#DC2626",
      fontFamily: "Corbel V2",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
    },
  },
  designBorder2Landing: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#37657F",
  },
  ratingBlockWrapperLanding: {
    display: "flex",
    alignItems: "center",
    gap: 9,
    marginTop: 25,
    "@media (max-width: 575px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
    },
  },
  ratingSubmitBtnLanding: {
    borderRadius: "8px",
    background: "#37657F",
    padding: "10px 16px",
    color: "#FFF",
    textTransform: "capitalize" as const,
    fontFamily: "Corbel V2",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bold" as const,
    lineHeight: "24px",
    "&:hover": {
      color: "#FFF",
      background: "#37657F",
      cursor: "not-allowed",
    },

  },

};

export default withStyles(webStyles)(LandingPage);
