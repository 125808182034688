import React from "react";

// Customizable Area Start
import { Box, IconButton, Paper, LinearProgress } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";
import Slider from "react-slick";
import "../assets/css/MyCourses.css";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

let settings = {
  dots: false,
  speed: 1200,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 1,
  adaptiveHeight: true,
  autoplay: false,
  nav: true,
  autoplaySpeed: 2000,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1499,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

let setting_1 = {
  dots: false,
  speed: 1200,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  nav: true,
  autoplaySpeed: 2000,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

import MyCoursesController, { Props } from "./MyCoursesController";
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class MyCourse extends MyCoursesController {
  constructor(props: Props) {
    super(props);
  }

  renderCourseTab = () => {
    return <ul>
    <li
      className={
        this.state.toggleTabpan === "ongoing" ? `current` : ""
      }
    >
      <span>
        <button
          type="button"
          data-test-id="setOngoing"
          onClick={() =>
            this.setState({ toggleTabpan: "ongoing" })
          }
        >
          {i18nJs.t('Ongoing')}
        </button>
      </span>
    </li>
    <li
      className={
        this.state.toggleTabpan === "completed"
          ? `current`
          : ""
      }
    >
      <span>
        <button
          type="button"
          data-test-id="setCompleted"
          onClick={() =>
            this.setState({ toggleTabpan: "completed" })
          }
        >
          {i18nJs.t('Completed')}
        </button>
      </span>
    </li>
  </ul>
  }

  renderInputHistory = () => {
    return(
      this.state.searchHistory && <div className="home-search-block-contant" ref={this.state.divRef}>
        <div className={`${this.state.searchHistoryData.length >= 6 ? "home-category-block-content-norecord-history-length" : ""}`}>
          {this.state.searchHistoryData.length ? (
            this.state.searchHistoryData.map((sub:string,ind:number) => {
          return(
            <div className='home-search-block-contant-recent-first' key={ind}>
              <div className="home-search-block-contant-recent-second" >
                  <HistoryIcon/>
                  
                <div className="home-search-block-contant-recent-second-name" onClick={() => {this.onClickSearchValueStudentMycourses(sub)}}>
                  {sub}
                </div>
              </div>
              <div>
                <IconButton onClick={() => this.selectHistoryDeleteStudentMycourses(ind)} data-test-id="button-icon">
                  <DeleteIcon/>
                </IconButton>
              </div>
            </div>
          )
        })
      ):(
        <div className="home-category-block-content-norecord-history">
          <p>{i18nJs.t('No Data')}</p>
        </div>
      )}
    </div>
      <div className="home-search-btn-container">
        <button className="button-green-clear" onClick={() => this.clearAllHistoryStudentMycourses()}>
        {i18nJs.t('Clear All')}
        </button>
      </div>
    </div>
    )
  }

  renderTabpan = () =>{
    return(
      <>
      {this.state.toggleTabpan === "ongoing" &&
        this.state.filteredOngoingCourses.length === 0 ? (
          <Box textAlign="center">{i18nJs.t('No Courses Available')}</Box>
        ) : null}
        {this.state.toggleTabpan === "completed" &&
        this.state.filteredCompletedCourses.length === 0 ? (
          <Box textAlign="center">{i18nJs.t('No Courses Available')}</Box>
        ) : null}
      </>
    )
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="My Courses"
        >
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <div className="content-wrapper">
              <div className="home-search-block">
                <div className='w-100 position-relative'>
                  <input
                    data-test-id="inputSerch"
                    type="search"
                    placeholder={i18nJs.t('Search here')}
                    value={this.state.searchValue}
                    autoFocus={true}
                    onClick={() => this.onClickSearchModalOpenStudentMycourses()}
                    onChange={this.handleSearchChange3StudentMycourses}
                      onKeyUp={(e) => {
                    if (e.key === "Enter") this.onClickSearchModalCloseStudentMycourses()}}
                    />
                   {this.renderInputHistory()}
                </div>
              </div>
              <div className="item-wrapper">
                <div className="inner-heading">
                  <h6>{i18nJs.t('My Course')}</h6>
                </div>
                <div className="course-tab">
                  <div className="course-tab-nav">
                    {this.renderCourseTab()}
                  </div>
                  {this.renderTabpan()}

                  <div className="course-tab-link">
                    {this.state.toggleTabpan === "ongoing" && (
                      <div className="course_slider ongoing_course_slider">
                        <Slider
                          {...settings}
                          infinite={
                            this.state.filteredOngoingCourses.length >= 4
                          }
                        >
                          {this.state?.filteredOngoingCourses.map(
                            (item, index) => {
                              return (
                                <div
                                  key={item.id}
                                  onClick={() => this.onClickRedirct(item.id)}
                                  data-test-id="checkid"
                                  className="course-tab-block"
                                >
                                  {item.courseImage ? (
                                    <img
                                      key={index}
                                      src={baseURL + item.courseImage}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "120px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        borderBottom: "0.1px solid #94A3B8",
                                      }}
                                    >
                                     {i18nJs.t('No image available')} 
                                    </div>
                                  )}
                                  <div className="tab-process-block">
                                    <span>{item.totalPercentage}%</span>
                                    <span>
                                      {item.numberOfLessComple}/
                                      {item.numberOfLessons}{i18nJs.t('Sessions')} 
                                    </span>                                                                      
                                  </div>
                                  <div className="linear_progress_bar_block">
                                    <LinearProgress
                                      variant="determinate"
                                      value={item.totalPercentage}
                                      className="process-bar-clr"                                      
                                    />
                                  </div>
                                  <div className="course_slider_content_wrap">
                                    <h6>{item.courseName}</h6>
                                    <small>{i18nJs.t('A Course by')} {item.lecturer}.</small>
                                    <p>{this.categoriesName(item.categoryId)}</p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </Slider>
                      </div>
                    )}
                    {this.state.toggleTabpan === "completed" && (
                      <div className="course_slider completed_course_slider">
                        <Slider
                          {...settings}
                          infinite={
                            this.state.filteredCompletedCourses.length >= 4
                          }
                        >
                          {this.state?.filteredCompletedCourses.map(
                            (elem, index) => {
                              return (
                                <div
                                  key={elem.id}
                                  onClick={() => this.onClickRedirct(elem.id)}
                                  data-test-id="checkid"
                                  className="course-tab-block"
                                >
                                  {elem.courseImage ? (
                                    <img
                                      key={index}
                                      src={baseURL + elem.courseImage}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "120px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        borderBottom: "0.1px solid #94A3B8",
                                      }}
                                    >
                                      {i18nJs.t('No image available')} 
                                    </div>
                                  )}

                                  <div className="tab-process-block">
                                    <span>{elem.totalPercentage}%</span>
                                    <span>
                                      {elem.numberOfLessComple}/
                                      {elem.numberOfLessons} {i18nJs.t('Sessions')}
                                    </span>
                                  </div>
                                  <div className="linear_progress_bar_block">
                                    <LinearProgress
                                      variant="determinate"
                                      value={elem.totalPercentage}
                                      className="process-bar-clr"                                     
                                    />
                                  </div>
                                  <Box className="course_slider_content_wrap">
                                    <h6>{elem.courseName}</h6>
                                    <small>{i18nJs.t('A Course by')} {elem.lecturer}.</small>
                                    <p>{this.categoriesName(elem.categoryId)}</p>
                                  </Box>
                                </div>
                              );
                            }
                          )}
                        </Slider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="item-wrapper">
                <div className="inner-heading">
                  <h6>{i18nJs.t('Upcoming Sessions')}</h6>
                </div>
                {this.state.validLiveSessions.length === 0 ? (
                  <Box textAlign="center">{i18nJs.t('No Upcoming Sessions')}</Box>
                ) : null}

                <div className="upcoming-wrapper">
                  <Slider
                    {...setting_1}
                    infinite={this.state.validLiveSessions.length >= 4}
                  >
                    {this.state.validLiveSessions.map(
                      (elel: {
                        attributes: {
                          course: {
                            description: string;
                            course_name: string;
                            lecturer: string;
                            category_id: number;
                          };
                          account: {
                            first_name: string;
                            last_name: string;
                          };
                          join_url: string;
                          time_from: string;
                          time_to: string;
                          date: string;
                          thumbnail: string;
                          subject:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                        };
                      }) => {
                        return (
                          <div className="upcoming-block" data-test-id="testid">
                            <div className="block_module" id="checkidhaoi">
                              <div
                                className="inner-upcoming checkingin"
                                id="checkid"
                              >
                                <div className="upcoming-image classtest">
                                  {elel.attributes.thumbnail ? (
                                    <img
                                      src={baseURL + elel.attributes.thumbnail}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "113px",
                                        textAlign: "center",
                                        borderBottom: "0.1px solid #94A3B8",
                                        marginBottom: "16px"
                                      }}
                                    >
                                      {i18nJs.t('No image available')} 
                                    </div>
                                  )}
                                </div>
                                <div className="upcoming-content">
                                  <h6 data-test-id="hellotest">
                                    {elel.attributes.course.course_name}
                                  </h6>
                                  <small>
                                  {i18nJs.t('A Course by')}{" "}
                                    {elel.attributes.account.first_name}{" "}
                                    {elel.attributes.account.last_name}.
                                  </small>
                                  <p>
                                    {this.categoriesName(
                                      elel.attributes.course.category_id
                                    )}
                                  </p>
                                  <div className="upcoming-time" id="heytest">
                                    <span className="testclasshere">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M5.97472 3.23397L3.41639 5.3673C3.06639 5.65897 2.54139 5.6173 2.24972 5.25897C1.94972 4.90897 1.99972 4.38397 2.34972 4.08397L4.91639 1.95064C5.26639 1.65897 5.79139 1.70064 6.08305 2.05897C6.38305 2.40897 6.33305 2.93397 5.97472 3.23397ZM17.6497 4.08397L15.0831 1.95064C14.7331 1.65897 14.2081 1.70064 13.9081 2.05897C13.6164 2.40897 13.6664 2.93397 14.0164 3.23397L16.5747 5.3673C16.9247 5.65897 17.4497 5.6173 17.7497 5.25897C18.0497 4.90897 17.9997 4.38397 17.6497 4.08397ZM13.2247 12.6173L10.4164 10.9506V7.17564C10.4164 6.8423 10.1497 6.57564 9.81639 6.57564H9.76639C9.43305 6.57564 9.16639 6.8423 9.16639 7.17564V11.109C9.16639 11.4006 9.31639 11.6756 9.57472 11.8256L12.6164 13.6506C12.8997 13.8173 13.2664 13.734 13.4331 13.4506C13.6081 13.159 13.5164 12.784 13.2247 12.6173ZM9.99972 3.2423C5.85805 3.2423 2.49972 6.60064 2.49972 10.7423C2.49972 14.884 5.85805 18.2423 9.99972 18.2423C14.1414 18.2423 17.4997 14.884 17.4997 10.7423C17.4997 6.60064 14.1414 3.2423 9.99972 3.2423ZM4.16639 10.7423C4.16639 13.959 6.78305 16.5756 9.99972 16.5756C13.2164 16.5756 15.8331 13.959 15.8331 10.7423C15.8331 7.52564 13.2164 4.90897 9.99972 4.90897C6.78305 4.90897 4.16639 7.52564 4.16639 10.7423Z"
                                          fill="#0F172A"
                                        />
                                      </svg>
                                      {this.formatDate(elel.attributes.date)}
                                    </span>
                                    <span id="secondtest">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.3026 3.18166H16.0602C16.8935 3.18166 17.5753 3.86347 17.5753 4.69681V16.818C17.5753 17.6514 16.8935 18.3332 16.0602 18.3332H3.93898C3.10565 18.3332 2.42383 17.6514 2.42383 16.818V4.69681C2.42383 3.86347 3.10565 3.18166 3.93898 3.18166H4.69656V2.42408C4.69656 2.00741 5.03746 1.6665 5.45413 1.6665C5.8708 1.6665 6.21171 2.00741 6.21171 2.42408V3.18166H13.7875V2.42408C13.7875 2.00741 14.1284 1.6665 14.545 1.6665C14.9617 1.6665 15.3026 2.00741 15.3026 2.42408V3.18166ZM4.69656 16.818H15.3026C15.7193 16.818 16.0602 16.4771 16.0602 16.0604V6.96953H3.93898V16.0604C3.93898 16.4771 4.27989 16.818 4.69656 16.818Z"
                                          fill="#0F172A"
                                        />
                                      </svg>
                                      {elel.attributes.time_from} to{" "}
                                      {elel.attributes.time_to}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="upcoming-button"
                                data-test-id="datatestid"
                              >
                                <button className="button-gray" id="checkidone">
                                  Live{" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={25}
                                    height={24}
                                    viewBox="0 0 25 24"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M21.5 3H3.5C2.4 3 1.5 3.9 1.5 5V17C1.5 18.1 2.4 19 3.5 19H8.5V20C8.5 20.55 8.95 21 9.5 21H15.5C16.05 21 16.5 20.55 16.5 20V19H21.5C22.6 19 23.49 18.1 23.49 17L23.5 5C23.5 3.89 22.6 3 21.5 3ZM20.5 17H4.5C3.95 17 3.5 16.55 3.5 16V6C3.5 5.45 3.95 5 4.5 5H20.5C21.05 5 21.5 5.45 21.5 6V16C21.5 16.55 21.05 17 20.5 17ZM11 14.15L14.98 11.87C15.65 11.48 15.65 10.52 14.98 10.13L11 7.85C10.33 7.47 9.5 7.95 9.5 8.72V13.28C9.5 14.04 10.33 14.53 11 14.15Z"
                                      fill="#37657F"
                                    />
                                  </svg>
                                </button>
                                {this.isUpcomingSession(
                                  elel.attributes.date
                                ) ? (
                                  <button
                                    className="btn button-green"
                                    style={{
                                      border: "1px solid #8f9194",
                                      background: "#F1F5F9",
                                      color: "#8f9194",
                                    }}
                                    disabled={true}
                                    data-test-id="btn button-upcoming"
                                  >
                                    Upcoming
                                  </button>
                                ) : (
                                  <button
                                    className="btn button-green"
                                    data-test-id="btn button-green"
                                    onClick={() =>
                                      this.goToZoomFunct(
                                        elel.attributes.join_url
                                      )
                                    }
                                  >
                                    Join Now
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
    );
  }
}


const HistoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.14407 12.0015C4.14407 6.95154 8.31407 2.86154 13.4041 3.00154C18.0941 3.13154 22.0141 7.05154 22.1441 11.7415C22.2841 16.8315 18.1941 21.0015 13.1441 21.0015C11.0541 21.0015 9.14407 20.2915 7.62407 19.0915C7.15407 18.7315 7.12407 18.0215 7.54407 17.6015C7.90407 17.2415 8.46407 17.2115 8.86407 17.5215C10.0441 18.4515 11.5341 19.0015 13.1441 19.0015C17.0441 19.0015 20.1941 15.8115 20.1441 11.9015C20.0941 8.18154 16.9641 5.05154 13.2441 5.00154C9.32407 4.95154 6.14407 8.10154 6.14407 12.0015H7.93407C8.38407 12.0015 8.60407 12.5415 8.29407 12.8515L5.50407 15.6515C5.30407 15.8515 4.99407 15.8515 4.79407 15.6515L2.00407 12.8515C1.68407 12.5415 1.90407 12.0015 2.35407 12.0015H4.14407ZM12.1441 8.75154C12.1441 8.34154 12.4841 8.00154 12.8941 8.00154C13.3041 8.00154 13.6441 8.34154 13.6441 8.74154V12.1415L16.5241 13.8515C16.8741 14.0615 16.9941 14.5215 16.7841 14.8815C16.5741 15.2315 16.1141 15.3515 15.7541 15.1415L12.6341 13.2915C12.3341 13.1115 12.1441 12.7815 12.1441 12.4315V8.75154Z" fill="#37657F"/>
  </svg>
);

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#37657F"/>
  </svg>
);

