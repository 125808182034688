Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "assessmenttest";
exports.labelBodyText = "assessmenttest Body";
exports.AllQuestionsAPIEndPoint = "bx_block_assessmenttest/student_assessments?assessment_id="
exports.assessmentTestEndPoint = "bx_block_assessmenttest/student_assessments/assessment_test"
exports.studentAssessmentsEndPoint = "bx_block_assessmenttest/student_assessments"
exports.AllAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments"
exports.AllSubjectiveAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments/students_with_assessment_status"
exports.AllCourseAPIEndPoint = "bx_block_assessmenttest/assessments/course_without_assessment"
exports.singleAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments/"
exports.AssessmentViewResultAPIEndPoint = "bx_block_assessmenttest/student_assessments/view_result?assessment_id="
exports.retakeTestApiEndPoint = "bx_block_assessmenttest/student_assessments/retake_test?assessment_id="
exports.createAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments"
exports.editAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments/"
exports.singleQuestionCreateAPIEndPoint = "bx_block_assessmenttest/assessments/add_question"
exports.deleteAssessmentAPIEndPoint = "bx_block_assessmenttest/assessments/"
exports.deleteAssessmentQuestionAPIEndPoint = "bx_block_assessmenttest/assessments/remove_question"

exports.btnExampleTitle = "CLICK ME";
exports.httpGetType = "GET"
exports.postApiMethod = "POST"
exports.PutApiMethod = "PUT"
exports.DeleteApiMethod = "DELETE"
// Customizable Area End