export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const radiocheck = require("../assets/radiocheck.png")
export const radionocheck = require("../assets/radionocheck.png")
export const checkbox = require("../assets/checkbox.png")
export const checkboxcheckin = require("../assets/checkboxcheckin.png")
export const Course1 = require("../assets/course1.png");
export const Course2 = require("../assets/course2.png");
export const Course3 = require("../assets/course3.png");
export const Course4 = require("../assets/course4.png");
export const Arrow = require("../assets/arrow.svg")  
export const Arrows = require("../assets/arrowdrops.png")