import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string | null;
  toggleTabpan: string;
  isLoading: boolean;
  allStudCourses: Array<Course>;
  allStudFinishCourses: Array<Course>;
  filteredIncompleteCourses: Array<Course>;
  filteredCompletedCourses: Array<Course>;
  filteredIncompleteStore: Array<Course>;
  filteredCompletedStore: Array<Course>;
  sessionsComplPer: number;
  viewLessions: number;
  totalLessions: number;
  searchTerm: string;
  liveSessions: Array<LiveSession>;
  validLiveSessions: any;
  getCategoriesData: any;
  searchHistoryData:string[];
  searchValue:string;
  searchHistory:boolean;
  divRef: React.MutableRefObject<HTMLDivElement | null>;
  filteredOngoingCourses: Array<Course>;
}

interface Data {}
interface LiveSession {
  data: [
    {
      id: string;
      attributes: {
        subject: string;
        date: string;
        show_live_address: boolean;
        comment: string;
        start_url: string;
        join_url: string;
        zoom_id: string;
        created_at: string;
        updated_at: string;
        time_to: string;
        time_from: string;
        account: {
          id: number;
          first_name: string;
          last_name: string;
          full_phone_number: string;
          country_code: string | null;
          phone_number: string | null;
          email: string;
          activated: boolean;
          device_id: string | null;
          unique_auth_id: string | null;
          password_digest: string;
          created_at: string;
          updated_at: string;
          user_name: string | null;
          platform: string | null;
          user_type: string | null;
          app_language_id: number | null;
          last_visit_at: string | null;
          is_blacklisted: boolean;
          suspend_until: string | null;
          status: string;
          role_id: number;
          stripe_id: string | null;
          stripe_subscription_id: string | null;
          stripe_subscription_date: string | null;
          full_name: string | null;
          gender: string | null;
          date_of_birth: string;
          age: number | null;
          middle_name: string;
          qualification: string;
          country: string | null;
          city: string | null;
          occupation: string | null;
          reset_password_token: string | null;
          reset_password_sent_at: string | null;
          department: string;
          major: string;
          experience: string;
          biography: string;
          automatic_reminder: boolean;
        };
        course: {
          id: number;
          course_name: string;
          created_at: string;
          updated_at: string;
          title: string;
          information: string;
          description: string;
          profile_id: number;
          category_id: number;
          language: string;
          level: string;
          price: number;
          course_type: string;
          no_of_lessons: number;
          total_duration_of_lessons: number;
          lecturer: string;
        };
        thumbnail: string;
      };
    }
  ];
}

interface LessonData {
  id: string;
  type: string;
  attributes: {
    title: string | null;
    description: string;
    lesson_video: {
      url: string;
    } | null;
    lesson_image: {
      url: string;
    } | null;
  };
}

interface Course {
  id: string;
  type: string;
  courseName: string;
  title: string;
  information: string;
  description: string;
  profileId: number;
  categoryId: number;
  language: string;
  courseType: string;
  numberOfLessons: number;
  numberOfLessComple: number;
  totalDurationOfLessons: number;
  lessons: Lesson[];
  courseImage: string;
  averageRating: number;
  lecturer: string;
  totalPercentage: number;
}

interface CourseData {
  id: string;
  type: string;
  attributes: {
    course_name: string;
    title: string;
    information: string;
    description: string;
    profile_id: number;
    category_id: number;
    language: string;
    level: string;
    price: number;
    course_type: string;
    no_of_lessons: number;
    no_of_lessons_completed: number;
    total_duration_of_lessons: number;
    lessons: {
      data: LessonData[];
    };
    course_image: {
      url: string;
    };
    average_rating: number;
    lecturer: string;
    total_number_of_lessons: number;
    number_of_lessons_completed: number;
  };
}

interface Lesson {
  id: string;
  type: string;
  title: string | null;
  description: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static instance: NavigationMenuController;
  // getStudCourseListApiId: string = "";
  getStudentSessionsId: string = "";
  getCategoriesForMyCouresApiCallId: any;
  getStudOngoingCourseListApiId: string = "";
  getStudCompletedCourseListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    NavigationMenuController.instance = this;
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      toggleTabpan: "ongoing",
      isLoading: false,
      allStudCourses: [],
      allStudFinishCourses: [],
      filteredIncompleteCourses: [],
      filteredCompletedCourses: [],
      filteredIncompleteStore: [],
      filteredCompletedStore: [],
      sessionsComplPer: 0,
      viewLessions: 0,
      totalLessions: 0,
      searchTerm: "",
      liveSessions: [],
      validLiveSessions: [],
      getCategoriesData: [],
      searchHistoryData:[],
      searchValue:"",
      searchHistory:false,
      divRef: React.createRef(),
      filteredOngoingCourses: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let authToken = (await getStorageData("token")) || "";
    this.setState({ token: authToken }, () => {
      this.getAllSessions();
      this.getCategoriesForMyCourses();
      this.getAllStudOngoingCourseList();
      this.getAllStudCompletedCourseList();
    });
    document.addEventListener("mousedown", this.handleOutsideClickStudentMycourses)
  
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );     
      if (apiRequestCallId === this.getStudentSessionsId) {
        responseJson && this.getLiveSesssionData(responseJson);
      }else if (apiRequestCallId === this.getStudOngoingCourseListApiId) {
        responseJson && this.getResponseongoingListData(responseJson);
      }else if (apiRequestCallId === this.getStudCompletedCourseListApiId) {
        responseJson && this.getResponseCompletedListData(responseJson);
      }

      if(apiRequestCallId === this.getCategoriesForMyCouresApiCallId && responseJson.data) {
        this.setState({getCategoriesData: responseJson.data})
      }
      
    }
    // Customizable Area End
   }

   onClickRedirct = (setId:string) => {
    this.props.navigation.navigate("FashionDesign",{id:setId})
  }
 

  getLiveSesssionData = (responseJson: LiveSession) => {
    if(responseJson.data == undefined) {
      this.setState({
        liveSessions: [],
     })
    } else {
      this.setState(
        {
          liveSessions: [responseJson],
        },
        () => {
          this.filterSessions();
        }
      );
    }
  };

  getResponseongoingListData = (responseJson: { data: CourseData[] }) => {    
    const ongoingCourses: Course[] = [];

    let totalPercentage;
    if (responseJson.data) {
      responseJson.data.forEach((courseData) => {
        if (courseData.attributes.no_of_lessons_completed === 0) {
          totalPercentage = 0;
        } else {
          totalPercentage = Math.floor(
            (courseData.attributes.no_of_lessons_completed /
              courseData.attributes.lessons.data.length) *
              100
          );
        }
        const lessons: Lesson[] = courseData.attributes.lessons.data.map(
          (lessonData) => ({
            id: lessonData.id,
            type: lessonData.type,
            title: lessonData.attributes.title,
            description: lessonData.attributes.description,
          })
        );

        const course: Course = {
          id: courseData.id,
          type: courseData.type,
          courseName: courseData.attributes.course_name,
          title: courseData.attributes.title,
          information: courseData.attributes.information,
          description: courseData.attributes.description,
          profileId: courseData.attributes.profile_id,
          categoryId: courseData.attributes.category_id,
          language: courseData.attributes.language,
          courseType: courseData.attributes.course_type,
          numberOfLessons: courseData.attributes.lessons.data.length,
          totalDurationOfLessons:
            courseData.attributes.total_duration_of_lessons,
          lessons: lessons,
          courseImage:
            courseData.attributes.course_image !== null
              ? courseData.attributes?.course_image.url
              : "",
          averageRating: courseData.attributes.average_rating,
          lecturer: courseData.attributes.lecturer,
          totalPercentage: totalPercentage,
          numberOfLessComple: courseData.attributes.no_of_lessons_completed,
        };
        ongoingCourses.push(course);    
      });    
    }

    this.setState({
      filteredOngoingCourses: ongoingCourses,
    });   
    this.setState({
      filteredIncompleteStore: ongoingCourses,
    });      
   
  };

  getResponseCompletedListData = (responseJson: { data: CourseData[] }) => {    
    const completedCourses: Course[] = [];
    let totalPercentage;
    if (responseJson.data) {
      responseJson.data.forEach((courseData) => {
        if (courseData.attributes.no_of_lessons_completed === 0) {
          totalPercentage = 0;
        } else {
          totalPercentage = Math.floor(
            (courseData.attributes.no_of_lessons_completed /
              courseData.attributes.lessons.data.length) *
              100
          );
        }
        const lessons: Lesson[] = courseData.attributes.lessons.data.map(
          (lessonData) => ({
            id: lessonData.id,
            title: lessonData.attributes.title,
            description: lessonData.attributes.description,
            type: lessonData.type,
          })
        );

        const course: Course = {
          id: courseData?.id,
          description: courseData.attributes.description,
          information: courseData.attributes.information,
          categoryId: courseData.attributes.category_id,
          numberOfLessons: courseData.attributes.lessons.data.length,
          language: courseData.attributes.language,
          courseName: courseData.attributes.course_name,
          profileId: courseData.attributes.profile_id,
          courseType: courseData.attributes.course_type,
          title: courseData.attributes.title,
          type: courseData.type,
          courseImage:
          courseData?.attributes?.course_image !== null
          ? courseData.attributes?.course_image.url : "",
          totalDurationOfLessons: courseData.attributes.total_duration_of_lessons,
          averageRating: courseData.attributes.average_rating,
          lecturer: courseData.attributes.lecturer,
          lessons: lessons,
          numberOfLessComple: courseData.attributes.no_of_lessons_completed,
          totalPercentage: totalPercentage,
        };
        
        if(totalPercentage >= 100){
          completedCourses.push(course);      
        }
      });
    }

    this.setState({
      filteredCompletedStore: completedCourses,
    });
    this.setState({
      filteredCompletedCourses: completedCourses,
    });   
  };

  goToZoomFunct = (zoomLink: string) => {
    window.open(zoomLink, "_blank");
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    const { filteredCompletedCourses, filteredIncompleteCourses } = this.state;

    let filteredIncompleteCourses1 = filteredIncompleteCourses;
    let filteredCompletedCourses1 = filteredCompletedCourses;

    if (searchTerm.length == 0) {
      this.setState({
        filteredIncompleteCourses: this.state.filteredIncompleteStore,
        filteredCompletedCourses: this.state.filteredCompletedStore,
      });
    } else {
      filteredIncompleteCourses1 = filteredIncompleteCourses.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm)
      );
      filteredCompletedCourses1 = filteredCompletedCourses.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm)
      );
      this.setState({
        filteredIncompleteCourses: filteredIncompleteCourses1,
        filteredCompletedCourses: filteredCompletedCourses1,
        searchTerm,
      });
    }
  };

  getAllStudOngoingCourseList = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );   
    this.getStudOngoingCourseListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardStudMyOngoingCourse
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStudCompletedCourseList = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );   
    this.getStudCompletedCourseListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardStudMyCompletedCourse
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSessions = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentSessionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.liveSessionsStudent
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getValidSessions = (sessions: any[]) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return sessions.filter((elel) => {
      const sessionDate = new Date(elel.attributes.date);
      const sessionEndTime = new Date(
        sessionDate.getFullYear(),
        sessionDate.getMonth(),
        sessionDate.getDate()
    );

    return sessionEndTime >= today;
    });
  };

  filterSessions = () => {
    let validSessions = this.getValidSessions(
      this.state.liveSessions[0]?.data || []
    );
    validSessions = this.sortCourses(validSessions);
    this.setState({ validLiveSessions: validSessions });
  };

  sortCourses = (validSessions: any) => {
   let returnSorted =  validSessions.sort((arrayA: any, arrayB: any) => {
      const dateA = new Date(arrayA.attributes.date);
      const dateB = new Date(arrayB.attributes.date);
      return dateA.getTime() - dateB.getTime();
    });

    return returnSorted;
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  isUpcomingSession = (dateString: string) => {
    const dateToCompare = new Date(dateString);
    const sessionDate = new Date(
      dateToCompare.getFullYear(),
      dateToCompare.getMonth(),
      dateToCompare.getDate()
    );

    const currentDate = new Date();
    const today = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

  return today < sessionDate;
  }

  categoriesName = (category_id: number) => {
    let category = this.state.getCategoriesData.map((item: any, index: number) => {
      if (Number(item.id) === category_id) {
        return item.attributes.name;
      }
    });
  
    return category;
  };

  getCategoriesForMyCourses = () => {
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesForMyCouresApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPointForMyCourses
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  selectHistoryDeleteStudentMycourses = (index:number) => {
    let deleteData = this.state.searchHistoryData.filter((object:any, indx:number) => indx !== index)
    this.setState({searchHistoryData:deleteData})
    localStorage.setItem("test",JSON.stringify(deleteData))
  }

  onClickSearchModalOpenStudentMycourses = () => {
    this.setState({searchHistory:true})
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now == prev){
      let data = localStorage.getItem('test')
      if(data)
      this.setState({searchHistoryData: JSON.parse(data)})
    }else{
      localStorage.removeItem('test')
      localStorage.removeItem('searchHistory')
    }
    //this.onSearchLogic()
  }


  handleOutsideClickStudentMycourses = (event:any) => {
    (this.state.divRef.current && !this.state.divRef.current.contains(event.target)) && this.onClickSearchModalCloseStudentMycourses()
  };

  onClickSearchModalCloseStudentMycourses = () => {
    const { searchValue, searchHistoryData } = this.state;

    if (searchValue.length !== 0 && !searchHistoryData.includes(searchValue)) {
      let searchDataModalCloseStudentMycourses = [searchValue, ...searchHistoryData]
      localStorage.setItem("test", JSON.stringify(searchDataModalCloseStudentMycourses));
      this.setState({
        searchHistory: false, 
        searchHistoryData: searchDataModalCloseStudentMycourses,
      });
    } else {
      this.setState({
        searchHistory: false,
      });
    }
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    if(now !== prev){
      let now = localStorage.getItem('user_id')
      localStorage.removeItem('searchHistory')
      if(now){
        localStorage.setItem('p',now)
      }
      localStorage.removeItem('test')  
    } 
  }

  onClickSearchValueStudentMycourses = (value:string) => {
    this.setState({searchValue:value,searchHistory:false})
    this.handleSearchChange2StudentMycourses(value.toLowerCase())
  }

  clearAllHistoryStudentMycourses = () => {
    localStorage.removeItem('test')
    this.setState({searchHistoryData:[],searchTerm:""})
    //this.onCallHistory()
  }

  handleSearchChange2StudentMycourses = (searchTerm: any) => {
    const { filteredCompletedCourses, filteredOngoingCourses } = this.state;
    let filteredOngoingCoursesChange2StudentMycourses = filteredOngoingCourses;
    let filteredCompletedCoursesChange2StudentMycourses = filteredCompletedCourses;
    if (searchTerm.length == 0) {
      this.setState({
        filteredOngoingCourses: this.state.filteredIncompleteStore,
        filteredCompletedCourses: this.state.filteredCompletedStore,
      });
    } else {
      filteredOngoingCoursesChange2StudentMycourses = filteredOngoingCourses.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm)
      );

      filteredCompletedCoursesChange2StudentMycourses = filteredCompletedCourses.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm)
      );
      this.setState({
        filteredOngoingCourses: filteredOngoingCoursesChange2StudentMycourses,
        filteredCompletedCourses: filteredCompletedCoursesChange2StudentMycourses,
        searchTerm,
      });
    }
  };

  handleSearchChange3StudentMycourses = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase(); 
    this.setState({searchValue:event.target.value})
    const { filteredIncompleteStore, filteredCompletedStore } = this.state;
    this.setState({ searchTerm }, () => {
      if (searchTerm === '') {
        this.setState({
          filteredOngoingCourses: filteredIncompleteStore,
          filteredCompletedCourses: filteredCompletedStore,
        });
      } else {
        const filteredOngoingCourses = filteredIncompleteStore.filter((course) =>
          course.courseName.toLowerCase().includes(searchTerm)
        );
        const filteredCompletedCourses = filteredCompletedStore.filter((course) =>
          course.courseName.toLowerCase().includes(searchTerm)
        );

        
        this.setState({
          filteredOngoingCourses,
          filteredCompletedCourses,
        });
      }
    });
  };
  // Customizable Area End
}
