import React from "react";

// Customizable Area Start
import { Box, Grid, ThemeProvider, createTheme, styled, MenuItem, Select, TextField, withStyles, InputAdornment, Checkbox, Button, Radio, FormLabel, IconButton, FormHelperText } from "@material-ui/core";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
// Customizable Area End

import AssessmenttestCreateController, {
    Props
} from "./AssessmenttestCreateController";
import { IconUnCheck, RadioIconUnCheck } from "./Assessmenttest.web";
import * as Yup from 'yup';
import i18nJs from "../../../components/src/TranslateLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#205e83",
            contrastText: "#fff"
        }
    }
});

export default class AssessmenttestCreate extends AssessmenttestCreateController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { allQuestion, errors } = this.state
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="Create Assessment"
                >
                    <AssessmenttestCreateContainer>
                        <Box className="main-container">
                            <Box>
                                <Box className="main-container-one">
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Assessment Name')}*</FormLabel>
                                                <AssessmentTestTextFieldMain
                                                    name="name"
                                                    value={allQuestion.name}
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                />
                                                <FormHelperText className="error-message">{!!errors.name && errors.name}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Course Name')}*</FormLabel>
                                                <Select
                                                    name="course_id"
                                                    value={allQuestion.course_id || "0"}
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder={i18nJs.t('Select Course')}
                                                    className="course-name-field"
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null,
                                                        style: { maxHeight: "calc(100% - 400px)" },
                                                    }}
                                                >
                                                    <MenuItem value="0" className="menu-style" disabled>{i18nJs.t('Select Course')}</MenuItem>
                                                    {this.state.allCourse.length > 0 && this.state.allCourse?.map((course: any, poss: number) => {
                                                        return (
                                                            <MenuItem className="menu-style" key={poss} value={course.id}>{course.course_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                <FormHelperText className="error-message">{!!errors.course_id && errors.course_id}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Passing Score')}</FormLabel>
                                                <AssessmentTestTextFieldMain
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    data-test-id={`assessment-questions-score`}
                                                    name="passing_score"
                                                    value={allQuestion.passing_score}
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                    type="number"
                                                />
                                                <FormHelperText className="error-message">{!!errors.passing_score && errors.passing_score}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel className="assessment-test-label">{i18nJs.t('Number of Retake Test')}*</FormLabel>
                                                <AssessmentTestTextFieldMain
                                                    onChange={this.handleNameChange}
                                                    onBlur={this.handleNameChange}
                                                    name="retake"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                    type="number"
                                                    value={allQuestion.retake}
                                                />
                                                <FormHelperText className="error-message">{!!errors.retake && errors.retake}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {allQuestion.questions_attributes.map((subs: any, subIndex: number) => (
                                                    <Box className="main-container-two" key={subIndex}>
                                                        <Box className="marks-field">

                                                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                                                <Grid item>
                                                                    <FormLabel className="assessment-test-label">{i18nJs.t('Marks')}*</FormLabel>
                                                                </Grid>
                                                                <Grid item>
                                                                    <AssessmentTestTextField
                                                                        type="number"
                                                                        variant="outlined"
                                                                        name='marks'
                                                                        data-test-id={`assessment-questions-marks-${subIndex}`}
                                                                        value={subs.marks}
                                                                        onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                        onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                        size="small"
                                                                        placeholder={i18nJs.t('TYPE_HERE')}
                                                                    />
                                                                    <FormHelperText className="error-message">{!!errors[`question_${subIndex}_marks`] && errors[`question_${subIndex}_marks`]}</FormHelperText>
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton disabled={allQuestion.questions_attributes.length === 1} data-test-id={`assessment-questions-removed-${subIndex}`} onClick={() => this.deleteQuestion(subIndex, subs.id)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                                                        </svg>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box component={"div"}>
                                                                    <Select
                                                                        name='question_type'
                                                                        value={subs.question_type}
                                                                        data-test-id={`assessment-questions-type-${subIndex}`}
                                                                        onChange={(event) => this.handleTypeChange(subIndex, event)}
                                                                        onBlur={(event) => this.handleTypeChange(subIndex, event)}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "left"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "left"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }}
                                                                        className="mutlti-select-option"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    >
                                                                        {this.state.questionsList.map((objs: any, inds: number) => {
                                                                            return (
                                                                                <MenuItem className="menu-style" key={inds} value={objs.value}>{objs.name}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <AssessmentTestTextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name='question'
                                                                    data-test-id={`assessment-questions-question_name-${subIndex}`}
                                                                    value={subs.question}
                                                                    onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    size="small"
                                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                                    InputProps={{
                                                                        startAdornment: (<InputAdornment position="start"><Box>1</Box></InputAdornment>)
                                                                    }}
                                                                />
                                                                <FormHelperText className="error-message">{!!errors[`question_${subIndex}_question`] && errors[`question_${subIndex}_question`]}</FormHelperText>
                                                                {subs.question_type === "dropdown" &&
                                                                    <Box style={{ display: 'flex', gap: '8px'}}>
                                                                        <Button className="add-new-question-dropdown" data-test-id="add-new-question" onClick={() => { this.addNewAnswerDropdown(subIndex) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                            </svg>
                                                                        </Button>
                                                                        <Button className="add-new-question-dropdown" data-test-id="remove-question" onClick={() => { this.removeAnswerDropdown(subIndex) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M7 11H17V13H7V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                            </svg>

                                                                        </Button>
                                                                    </Box>
                                                                }
                                                            </Grid>
                                                            {subs.answers_attributes.map((answer: any, answerIndex: number) => {
                                                                const correctOptions = subs.correct_answer?.split(',').map((option: any) => option.trim());
                                                                const finalvalue = correctOptions.includes(answer.answer);
                                                                return (
                                                                    <Grid item xs={12} sm={6} lg={3} key={answerIndex}>
                                                                        <Box className={finalvalue ? "radio-label-container" : "radio-label-container-error"}>
                                                                            {subs.question_type === "multiple_select" && <Checkbox className="p-0" color="default" checked={false} icon={<IconUnCheck />} checkedIcon={<IconUnCheck />} />}
                                                                            {subs.question_type === "single_select" && <Radio color="default" icon={<RadioIconUnCheck />} checkedIcon={<RadioIconUnCheck />} />}
                                                                            <AssessmentTestField
                                                                                name='answer'
                                                                                variant="outlined"
                                                                                value={answer.answer}
                                                                                fullWidth
                                                                                data-test-id={`question_${subIndex}_answer_${answerIndex}`}
                                                                                placeholder={i18nJs.t('TYPE_HERE')}
                                                                                onChange={(event) => this.handleAnswerChange(subIndex, answerIndex, event.target.value)}
                                                                                onBlur={(event) => this.handleAnswerChange(subIndex, answerIndex, event.target.value)}
                                                                            />
                                                                        </Box>
                                                                        <FormHelperText className="error-message">{!!errors[`question_${subIndex}_answer_${answerIndex}`] && errors[`question_${subIndex}_answer_${answerIndex}`]}</FormHelperText>
                                                                    </Grid>
                                                                )
                                                            })}
                                                            <Grid item xs={12}>
                                                                <FormLabel className="assessment-test-label">{i18nJs.t('Correct Answer')}*</FormLabel>
                                                                <AssessmentTestTextField
                                                                    name='correct_answer'
                                                                    value={subs.correct_answer}
                                                                    data-test-id={`assessment-questions-correct_answer-${subIndex}`}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    placeholder={i18nJs.t('TYPE_HERE')}
                                                                    onChange={(event) => this.handleQuestionChange(subIndex, event)}
                                                                    onBlur={(event) => this.handleQuestionChange(subIndex, event)}
                                                                />
                                                                <FormHelperText className="error-message">{!!errors[`question_${subIndex}_correct_answer`] && errors[`question_${subIndex}_correct_answer`]}</FormHelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className="margin-40">
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item>
                                                            <Button className="add-question-button" data-test-id="add-question-button" onClick={() => this.addQuestion()}>
                                                               {i18nJs.t('Add Questions')}
                                                                <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                                                </svg>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className="main-container-third">
                                    <Grid container spacing={3} justifyContent="flex-end">
                                        <Grid item>
                                            <Button type="button" data-test-id="go-back-assessment-list" onClick={() => this.onClickGoBack()} className="backButton-assessment">{i18nJs.t('Back')}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button disabled={this.state.submitFlag} type="button" className="submit-new-assessment-button" onClick={() => this.onSubmitCreateAssessment()} data-test-id="submit-assessment-button" >{i18nJs.t('Submit Assessment')}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </AssessmenttestCreateContainer>
                </NavigationMenu>
            </ThemeProvider >
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const AssessmenttestCreateContainer = styled(Box)({
    width: '100%',
    overflow: 'hidden',
    "& .main-container": {
        height: 'calc(100vh - 110px)',
        overflowX: 'hidden',
        background: '#f6f8fa',
        padding: 40,
        "&::-webkit-scrollbar": { width: 4 }, "&::-webkit-scrollbar-track": { boxShadow: "inset 0 0 5px transparent", borderRadius: 2, background: "#D7E0E5" }, "&::-webkit-scrollbar-thumb": { background: "#37657F" },
    },
    "& .menu-style": {
        fontFamily: "Corbel V2",
    },
    "& .error-message": {
        fontFamily: "Corbel V2",
        color: "#FF0000",
    },
    "& .main-container-one": {
        background: '#F8FAFC',
        border: "1px solid #94A3B8",
        padding: 24,
        borderRadius: 10
    },
    "& .MuiTypography-body1": {
        fontFamily: "Corbel V2"
    },
    "& .main-container-two": {
        background: '#F1F5F9',
        border: "1px solid #94A3B8",
        padding: 24,
        borderRadius: 10,
        marginBottom: 24,
        position: "relative"
    },
    "& .MuiIconButton-root": {
        padding: 5
    },
    "& .remove-button-assessment": {
        position: "absolute",
        top: 10,
        right: 10
    },
    "& .marks-field": {
        marginBottom: 8,
        "& .assessment-test-label": {
            margin: 0
        }
    },
    "& .mutlti-select-option": {
        height: 40,
        color: '#fff',
        background: "#37657F",
        borderRadius: 8,
        fontFamily: "Corbel V2",
        fontSize: 14,
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent"
        },
        "& .MuiSelect-icon": {
            color: '#fff'
        }
    },
    "& .radio-label-container": {
        background: '#fff',
        borderRadius: '6px',
        border: '1px solid #CBD5E1',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        "& .MuiFormControlLabel-label": {
            width: "100%"
        },
        "& .MuiFormControlLabel-root": {
            margin: 0,
            display: "flex",
            alignItems: "center",
            gap: 7
        },
        "& .MuiRadio-root": {
            padding: 0
        }
    },
    "& .radio-label-container-error": {
        background: '#fff',
        borderRadius: '6px',
        border: '1px solid #DC2626',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        "& .MuiFormControlLabel-label": {
            width: "100%"
        },
        "& .MuiFormControlLabel-root": {
            margin: 0,
            display: "flex",
            alignItems: "center",
            gap: 7
        },
        "& .MuiRadio-root": {
            padding: 0
        }
    },
    "& .add-question-button": {
        width: 160,
        height: 50,
        borderRadius: 8,
        textTransform: "none",
        fontFamily: "Corbel V2",
        background: "#37657F",
        color: "#fff",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
            display: 'flex',
            gap: '16px',
        },
        "& svg": {
            marginLeft: 10
        }
    },
    "& .add-new-question-dropdown": {
        width: 60,
        height: 30,
        borderRadius: 8,
        textTransform: "none",
        fontFamily: "Corbel V2",
        background: "#37657F",
        color: "#fff",
        marginTop: "10px",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .margin-40": {
    },
    "& .backButton-assessment": {
        width: 180,
        height: 50,
        borderRadius: 8,
        border: '1px solid #94A3B8',
        background: '#F1F5F9',
        textTransform: "none",
        fontFamily: "Corbel V2",
        color: "#37657F",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .submit-new-assessment-button": {
        width: 210,
        height: 50,
        borderRadius: 8,
        background: '#37657F',
        textTransform: "none",
        fontFamily: "Corbel V2",
        color: "#FFF",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .main-container-third": {
        marginTop: "40px"
    },
    "& .assessment-test-label": {
        color: '#334155',
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 10
    },
    "& .MuiFormLabel-root": {
        fontFamily: "Corbel V2",
    },
    "& .course-name-field": {
        height: 50,
        borderRadius: 8,
        fontFamily: "Corbel V2",
        color: "#37657F",
        background: '#fff',
        "& .MuiSelect-select:focus": {
            background: "transparent"
        },
        '& fieldset': {
            border: "1px solid #CBD5E1",
        },
        '&:hover fieldset': {
            border: '1px solid #205e83',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #205e83',
        },
    },

})

const AssessmentTestTextFieldMain = withStyles({
    root: {
        height: 50,
        overflow: "hidden",
        '& .MuiOutlinedInput-input': {
            height: 50,
            padding: "0 15px",
        },
        '& .MuiOutlinedInput-root': {
            height: 50,
            color: '#205e83',
            borderRadius: 8,
            background: '#FFF',
            fontFamily: "Corbel V2",
            "& ::placeholder": {
                fontFamily: "Corbel V2",
                fontSize: 14,
                fontWeight: 400,
                color: "#475569"
            },
            '& fieldset': {
                border: "1px solid #CBD5E1",
            },
            '&:hover fieldset': {
                border: '1px solid #205e83',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #205e83',
            },
        }
    },
})(TextField);



const AssessmentTestTextField = withStyles({
    root: {
        height: 40,
        overflow: "hidden",
        '& .MuiOutlinedInput-input': {
            height: 40,
            padding: '0 5px',
        },
        '& .MuiOutlinedInput-root': {
            height: 40,
            color: '#205e83',
            borderRadius: 8,
            background: '#FFF',
            fontFamily: "Corbel V2",
            "& ::placeholder": {
                fontFamily: "Corbel V2",
                fontSize: 14,
                fontWeight: 400,
                color: "#475569"
            },
            '& fieldset': {
                border: "1px solid #CBD5E1",
            },
            '&:hover fieldset': {
                border: '1px solid #205e83',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #205e83',
            },
        }
    },
})(TextField);

const AssessmentTestField = withStyles({
    root: {
        height: 25,
        zIndex: 100,
        '& .MuiOutlinedInput-input': {
            height: 25,
            padding: '0 5px',
        },
        '& .MuiOutlinedInput-root': {
            height: 28,
            color: '#205e83',
            borderRadius: 8,
            background: '#FFF',
            fontFamily: "Corbel V2",
            "& ::placeholder": {
                fontFamily: "Corbel V2",
                fontSize: 14,
                fontWeight: 400,
                color: "#475569"
            },
            '& fieldset': {
                border: "none",
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        }
    },
})(TextField);
// Customizable Area End
