import React from "react";

// Customizable Area Start
import { Box, Button, FormHelperText, FormLabel, Grid, InputAdornment, TextField, ThemeProvider, Typography, createTheme, styled, withStyles } from "@material-ui/core";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

import AssessmenttestResultController, {
    Props
} from "./AssessmenttestResultController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export class AssessmenttestMarks extends AssessmenttestResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { errors } = this.state
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title={i18nJs.t("Subjective Answer Marks")}
                >
                    <AssessmenttestMarksContainer>
                        <Box className="marks-main-container">
                            <Box className="main-container-one-marks">
                                <Box>
                                    <Typography className="marks-title">{this.state.assessmentSubjectiveQuestions[0]?.assessment_name}</Typography>
                                </Box>
                                {this.state.assessmentSubjectiveQuestions.map((objs: any, index: number) => {
                                    return (
                                        <Box key={index}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Box className="main-container-two-marks" >
                                                        <Box className="marks-field-assessment">
                                                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                                            <Grid item>
                                                                    <FormLabel className="assessment-test-label-marks">{i18nJs.t("Max Marks")}* : {objs.maximum_marks}</FormLabel>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormLabel className="assessment-test-label-marks">{i18nJs.t("Marks")}*</FormLabel>
                                                                </Grid>
    
                                                                <Grid item>
                                                                <AssessmentTestTextField
                                                                    type="number"
                                                                    variant="outlined"
                                                                    name="marks"
                                                                    data-test-id={`assessment-questions-marks-${index}`}
                                                                    value={this.state.assessmentSubjective[index]?.marks || ''}
                                                                    onChange={(event) => this.onChangeMarks(event, index, objs.maximum_marks)}
                                                                    size="small"
                                                                    placeholder="Type here"
                                                                />
                                                                <FormHelperText className="error-message-marks">
                                                                    {!!errors[`question_${index}_marks`] && errors[`question_${index}_marks`]}
                                                                </FormHelperText>
                                                            </Grid>

                                                            </Grid>
                                                        </Box>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <AssessmentTestTextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    name='question_name'
                                                                    value={objs.question}
                                                                    size="small"
                                                                    placeholder="Type here"
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        startAdornment: (<InputAdornment position="start"><Box>1</Box></InputAdornment>)
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormLabel className="assessment-test-label-marks">{i18nJs.t("Student Answer")}*</FormLabel>
                                                                <AssessmentTestTextField
                                                                    name='correct_answer'
                                                                    value={objs.subjective_answer}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    placeholder="Type here"
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormLabel className="assessment-test-label-marks">{i18nJs.t("Correct Answer")}*</FormLabel>
                                                                <AssessmentTestTextField
                                                                    name='correct_answer'
                                                                    value={objs.right_answer}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    placeholder="Type here"
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box className="main-container-third-marks">
                                <Grid container spacing={3} justifyContent="flex-end">
                                    <Grid item>
                                        <Button type="button" data-test-id="go-back-assessment-list-marks" onClick={() => this.onClickGoBack()} className="backButton-assessment-marks">{i18nJs.t("Back")}</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="button" data-test-id="submitNewAssessmentTest" className="submit-new-assessment-button-marks" onClick={() => this.onSubmitSubjectiveAssessment()} >{i18nJs.t("Submit")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </AssessmenttestMarksContainer>
                </NavigationMenu>
            </ThemeProvider>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const AssessmenttestMarksContainer = styled(Box)({
    "& .error-message-marks": { fontFamily: "Corbel V2", color: "#FF0000" },
    "& .marks-main-container": { height: 'calc(100vh - 110px)', overflowX: 'hidden', background: '#f6f8fa', padding: 40, "&::-webkit-scrollbar": { width: 4 }, "&::-webkit-scrollbar-track": { boxShadow: "inset 0 0 5px transparent", borderRadius: 2, background: "#D7E0E5" }, "&::-webkit-scrollbar-thumb": { background: "#37657F" } },
    "& .main-container-one-marks": { background: '#F8FAFC', border: "1px solid #94A3B8", padding: 24, borderRadius: 10 },
    "& .marks-field-assessment": { marginBottom: 8, "& .assessment-test-label-marks": { margin: 0 } },
    "& .marks-title": { fontFamily: "Corbel V2", fontSize: "18px", color: "#1E293B", fontWeight: 700 },
    "& .marks-title-information": { fontFamily: "Corbel V2", fontSize: "16px", color: "#64748B", fontWeight: 700, marginBottom: 20 },
    "& .assessment-test-label-marks": { fontFamily: "Corbel V2", color: '#334155', fontSize: 16, fontWeight: 400, marginBottom: 10 },
    "& .main-container-two-marks": { background: '#F1F5F9', border: "1px solid #94A3B8", padding: 24, borderRadius: 10, marginBottom: 24, position: "relative" },
    "& .backButton-assessment-marks": { width: 180, height: 50, borderRadius: 8, border: '1px solid #94A3B8', background: '#F1F5F9', textTransform: "none", fontFamily: "Corbel V2", color: "#37657F", "& span": { fontWeight: 700, fontSize: 16, } },
    "& .submit-new-assessment-button-marks": { width: 180, height: 50, borderRadius: 8, background: '#37657F', textTransform: "none", fontFamily: "Corbel V2", color: "#FFF", "& span": { fontWeight: 700, fontSize: 16, } },
    "& .main-container-third-marks": { margin: "40px 0" },
})

const AssessmentTestTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: 40, color: '#205e83', borderRadius: 8, background: '#FFF', fontFamily: "Corbel V2",
            '&.Mui-focused fieldset': { border: '1px solid #205e83' },
            '&:hover fieldset': { border: '1px solid #205e83' },
            '& fieldset': { border: "1px solid #CBD5E1" },
            "& ::placeholder": { fontFamily: "Corbel V2", fontSize: 14, fontWeight: 400, color: "#475569" },
        },
        '& .MuiOutlinedInput-input': { height: 40, padding: '0 5px' },
        height: 40, overflow: "hidden",
    },
})(TextField);
export default AssessmenttestMarks
// Customizable Area End
